<template>
    <div>
        <thanks-section></thanks-section>
        <footer-section></footer-section>
    </div>
</template>
<script>
import FooterSection from '../components/FooterSection.vue'
import ThanksSection from '../components/ThanksSection.vue'
export default {
    components: { ThanksSection, FooterSection }
}
</script>